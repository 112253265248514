import { render, staticRenderFns } from "./ProductInventoryBox.vue?vue&type=template&id=d9494e4c&scoped=true&"
import script from "./ProductInventoryBox.vue?vue&type=script&lang=js&"
export * from "./ProductInventoryBox.vue?vue&type=script&lang=js&"
import style0 from "./ProductInventoryBox.vue?vue&type=style&index=0&id=d9494e4c&scoped=true&lang=css&"
import style1 from "./ProductInventoryBox.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9494e4c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VChip,VCol,VDivider,VRow,VSimpleTable})
