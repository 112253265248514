<template>
  <div
    v-show="dialog"
    width="auto"
    class="preview-modal">
    <v-card class="preview-image-card">
      <div class="preview-image-box">
        <img
          :src="imgSrc"
          class="preview-image"
          alt="preview-image">
      </div>
      <v-btn
        class="preview-image-close"
        elevation="5"
        icon
        @click="closeDialog()">
        <v-icon color="black">
          mdi-close
        </v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    imgSrc: {
      type: String,
      default: null
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-image-card {
  position: relative;
  img.preview-image {
    height: 80vh;
    width: auto;
  }
  .preview-image-close {
    position: absolute;
    background-color: white;
    top: 10px;
    right: 10px;
  }
}

.preview-modal {
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
